import {Directive, ElementRef, Input, OnChanges} from '@angular/core';

import {WSimpleChanges} from '@shared/types/angular';

import {CssThemeVariableName} from '../theming/theme-types';

type CssVariablePrefixedName = `--${CssThemeVariableName}`;

export type CssPropsDictionary = {[k in CssThemeVariableName]?: string};

@Directive({
  selector: '[wSetCssProperties]',
})
export class SetCssPropertiesDirective implements OnChanges {
  @Input({required: true, alias: 'wSetCssProperties'}) propertiesDict: Readonly<CssPropsDictionary> | null;

  constructor(private element: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: WSimpleChanges<SetCssPropertiesDirective>) {
    if (changes.propertiesDict?.previousValue) {
      const prevKeys = Object.keys(changes.propertiesDict.previousValue) as CssThemeVariableName[];

      // Remove props that were previously assigned but absent in current props dict
      prevKeys
        .filter(prop => changes.propertiesDict?.currentValue?.[prop] === undefined)
        .forEach(prop => this.unsetVariable(prop));
    }

    if (changes.propertiesDict?.currentValue) {
      Object.entries(changes.propertiesDict.currentValue).forEach(([prop, value]: [CssThemeVariableName, string]) => {
        if (changes.propertiesDict?.previousValue?.[prop] !== value) {
          this.setVariable(prop, value);
        }
      });
    }
  }

  private setVariable(variableName: CssThemeVariableName, value: string) {
    this.element.nativeElement.style.setProperty(this.addPropertyPrefix(variableName), value);
  }

  private unsetVariable(variableName: CssThemeVariableName) {
    this.element.nativeElement.style.removeProperty(this.addPropertyPrefix(variableName));
  }

  private addPropertyPrefix(name: CssThemeVariableName): CssVariablePrefixedName {
    return `--${name}`;
  }
}
