<div class="page-banner page-banner_{{ type }}">
  <div class="page-banner__body">
    <span *ngIf="tag" class="page-banner__tag page-banner__tag_{{ tag.type || type }}">{{ tag.text }}</span>
    <ng-container *ngIf="isComponentContent(content)">
      <ng-container
        [ngComponentOutlet]="content"
        [ndcDynamicInputs]="contentInputs"
        [ndcDynamicAttributes]="{class: 'page-banner__content'}"
      />
    </ng-container>
    <w-template-or-text *ngIf="!isComponentContent(content)" class="page-banner__content" [value]="content" />
  </div>
  <button
    *ngIf="isDismissible"
    class="page-banner__close"
    (click)="onDismiss.emit()"
    wIconButton="close"
    wIconButtonAriaLabel="Dismiss banner"
  ></button>
</div>
