import {NgModule} from '@angular/core';
import {DynamicAttributesModule, DynamicIoModule} from 'ng-dynamic-component';

import {IconButtonComponent} from '@shared/components/icon-button/icon-button.component';

import {SharedModule} from '../../shared.module';
import {PlanUsageAlertBannerComponent} from '../../components/banners-content/plan-usage-alert-banner/plan-usage-alert-banner.component';
import {DelegatedAdminErrorBannerComponent} from '../../components/banners-content/delegated-admin-error-banner/delegated-admin-error-banner.component';
import {ResendEmailWarningBannerComponent} from '../../components/banners-content/resend-email-warning-banner/resend-email-warning-banner.component';
import {TeamInvitationsBannerComponent} from '../../components/banners-content/team-invitations-banner/team-invitations-banner.component';
import {TrialWarningBannerComponent} from '../../components/banners-content/trial-warning-banner/trial-warning-banner.component';
import {EnvironmentTypeBarComponent} from '../../components/environment-type-bar/environment-type-bar.component';
import {PluralModule} from '../../pipes/plural.pipe';

import {PageBannersComponent} from './page-banners/page-banners.component';
import {PageBannerComponent} from './page-banner/page-banner.component';
import {PageBannerButtonComponent} from './page-banner-button/page-banner-button.component';

const standaloneComponents = [EnvironmentTypeBarComponent];
const components = [PageBannersComponent, PageBannerComponent, PageBannerButtonComponent];
const contentComponents = [
  PlanUsageAlertBannerComponent,
  TeamInvitationsBannerComponent,
  ResendEmailWarningBannerComponent,
  TrialWarningBannerComponent,
  DelegatedAdminErrorBannerComponent,
];

@NgModule({
  imports: [
    SharedModule,
    DynamicIoModule,
    DynamicAttributesModule,
    PluralModule,
    IconButtonComponent,
    ...standaloneComponents,
  ],
  declarations: [...components, ...contentComponents],
  exports: [...components, ...standaloneComponents],
})
export class PageBannersModule {}
