<div *ngIf="!hidden" class="flash" [class.flash_with-close]="showCloseIcon" [ngClass]="classes">
  <button
    *ngIf="showCloseIcon"
    class="flash__close"
    type="button"
    (click)="handleCloseClick()"
    wIconButton="close"
    wIconButtonAriaLabel="Close notification"
    wIconButtonIconClass="flash__close-icon"
  ></button>

  <div *ngIf="header" class="flash__title">
    {{ header }}
  </div>
  <div class="flash__content" [class.flash__content_no-title]="!header">
    <ng-content />
  </div>
</div>
