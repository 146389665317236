import {ChangeDetectionStrategy, Component} from '@angular/core';

import {AbstractDateTimeLabelComponent} from '@shared/components/date-time-label/abstract-date-time-label.component';
import {DateTimeLabelTemplateComponent} from '@shared/components/date-time-label/date-time-label-template/date-time-label-template.component';

@Component({
  selector: 'w-date-time-label',
  templateUrl: './date-time-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DateTimeLabelTemplateComponent],
})
export class DateTimeLabelComponent extends AbstractDateTimeLabelComponent {}
