import {Pipe, PipeTransform} from '@angular/core';
import {DateTimeOptions} from 'luxon';

import {parseDateTime} from '../utils/dates/parse-date-time';
import {AbstractDateFormatService} from '../services/abstract-date-format.service';
import {NullableDateLike, TimeZone} from '../types';

export type DateFormatAlias = 'date' | 'dateTime' | 'dateTimeWithZone' | 'monthOfYear' | 'time' | 'timeWithSeconds';

export function formatDate(
  dateLike: NullableDateLike,
  outputFormat: string,
  outputTimezone: TimeZone | null = null,
  parseOptions?: DateTimeOptions,
): string {
  const dateTime = parseDateTime(dateLike, null, parseOptions);

  if (dateTime) {
    const dateInTimezone = outputTimezone ? dateTime.setZone(outputTimezone) : dateTime;

    return dateInTimezone.toFormat(outputFormat);
  }

  return '';
}

@Pipe({
  name: 'wDate',
  standalone: true,
})
export class DatePipe<AdditionalDateFormatAlias = never> implements PipeTransform {
  constructor(protected dateFormat: AbstractDateFormatService) {}

  transform(
    dateLike: NullableDateLike,
    format: DateFormatAlias | AdditionalDateFormatAlias = 'dateTime',
    timezone: TimeZone | null = null,
    parseOptions?: DateTimeOptions,
  ): string {
    return formatDate(dateLike, this.expandFormatAlias(format), timezone, parseOptions);
  }

  protected expandFormatAlias(format: DateFormatAlias | AdditionalDateFormatAlias): string {
    switch (format) {
      case 'date':
        return this.dateFormat.date;
      case 'dateTime':
        return this.dateFormat.dateTime;
      case 'dateTimeWithZone':
        return this.dateFormat.dateTimeWithZone;
      case 'monthOfYear':
        return this.dateFormat.monthOfYear;
      case 'time':
        return this.dateFormat.time;
      case 'timeWithSeconds':
        return this.dateFormat.timeWithSeconds;
      default:
        return this.dateFormat.dateTime;
    }
  }
}
