import {ChangeDetectorRef, Directive, Input, NgZone, OnChanges, OnDestroy, inject} from '@angular/core';
import {Subscription, interval} from 'rxjs';
import {DateTime} from 'luxon';

import {TooltipDirective} from '../../modules/tooltip/tooltip.directive';
import {DateFormatAlias, DatePipe, formatDate} from '../../pipes/date.pipe';
import {NullableDateLike, TimeZone} from '../../types';
import {WSimpleChanges} from '../../types/angular';
import {parseDateTime} from '../../utils/dates/parse-date-time';
import {pluralize} from '../../pipes/plural.pipe';
import {AbstractDateFormatService} from '../../services/abstract-date-format.service';

const FROM_NOW_FORMATS = ['fromNow', 'fromNowLimitToPast', 'fromNowLimitToFuture'] as const;
const FROM_NOW_UPDATE_INTERVAL = 30 * 1000;
const TIME_ATTRIBUTE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSZZ";

type FromNowFormatAlias = (typeof FROM_NOW_FORMATS)[number];

export type DateTimeLabelFormatAlias = DateFormatAlias | FromNowFormatAlias;

@Directive()
export abstract class AbstractDateTimeLabelComponent<AdditionalDateTimeLabelFormat = never>
  implements OnChanges, OnDestroy
{
  @Input({required: true}) date: NullableDateLike;
  @Input() format: DateTimeLabelFormatAlias | AdditionalDateTimeLabelFormat = 'dateTime';
  @Input() timezone?: TimeZone;
  @Input() showTooltip = true;
  @Input() tooltipFormat: DateFormatAlias = 'dateTimeWithZone';
  @Input() tooltipPlacement: TooltipDirective['placement'] = 'top';

  formattedDate: string;
  timeAttribute: string;
  toolTipDate: string | null;

  private fromNowUpdateSub: Subscription | null;
  private cd = inject(ChangeDetectorRef);
  private ngZone = inject(NgZone);
  private dateFormat = inject(AbstractDateFormatService);
  private datePipe = inject(DatePipe);

  ngOnChanges(changes: WSimpleChanges<AbstractDateTimeLabelComponent>) {
    if (changes.format || changes.date || changes.timezone) {
      this.setFormattedDate();
    }

    if (changes.showTooltip || changes.tooltipFormat || changes.date || changes.timezone) {
      this.setTooltipDate();
    }

    if (changes.date || changes.timezone) {
      this.timeAttribute = formatDate(this.date, TIME_ATTRIBUTE_FORMAT, this.timezone);
    }
  }

  ngOnDestroy() {
    this.fromNowUpdateSub?.unsubscribe();
  }

  get isFromNow(): boolean {
    return FROM_NOW_FORMATS.includes(this.format as FromNowFormatAlias);
  }

  protected setFormattedDate() {
    if (this.isFromNow) {
      this.formattedDate = this.getFromNowDate();
      this.scheduleFromNowUpdate();
    } else {
      this.formattedDate = this.datePipe.transform(this.date, this.format as DateFormatAlias, this.timezone);
    }
  }

  private setTooltipDate() {
    this.toolTipDate = this.showTooltip ? this.datePipe.transform(this.date, this.tooltipFormat, this.timezone) : null;
  }

  private scheduleFromNowUpdate() {
    this.fromNowUpdateSub?.unsubscribe();

    this.ngZone.runOutsideAngular(() => {
      this.fromNowUpdateSub = interval(FROM_NOW_UPDATE_INTERVAL).subscribe(() => {
        this.formattedDate = this.getFromNowDate();
        this.cd.detectChanges();
      });
    });
  }

  private getFromNowDate(): string {
    let dateTime = parseDateTime(this.date);

    if (!dateTime) {
      return '';
    }

    const now = DateTime.now();

    if (this.format === 'fromNowLimitToPast' && dateTime > now) {
      // Will show "a few seconds ago"
      dateTime = now;
    } else if (this.format === 'fromNowLimitToFuture' && dateTime <= now) {
      // Will show "in a few seconds"
      dateTime = now.plus({seconds: 1});
    }

    return dateTime <= now ? this.formatPastDate(dateTime, now) : this.formatFutureDate(dateTime, now);
  }

  private formatPastDate(date: DateTime, now: DateTime): string {
    const diffMinutes = DateTime.now().diff(date, 'minutes').minutes;
    const diffSeconds = DateTime.now().diff(date, 'seconds').seconds;
    const diffDays = DateTime.now().startOf('day').diff(date, 'days').days;

    switch (true) {
      // 0 to 44 seconds: a few seconds ago
      case diffSeconds <= 44:
        return 'a few seconds ago';
      // 45 to 89 seconds: a minute ago
      case diffSeconds <= 89:
        return 'a minute ago';
      // 90 seconds to 44 minutes: 2 minutes ago ... 44 minutes ago
      case diffMinutes <= 44:
        return `${pluralize(Math.floor(diffMinutes), 'minute')} ago`;
      // 45 minutes to 24 hours or the same day: at 4:09 am
      case now.hasSame(date, 'day'):
        return date.toFormat(this.getFromNowFormat('sameDay'));
      // 25 hour to 48 hours or the day before: Yesterday at 4:09 am
      case DateTime.now().startOf('day').minus({day: 1}).hasSame(date, 'day'):
        return date.toFormat(this.getFromNowFormat('prevDay'));
      // 49 hours to 6 days or the last week: Friday at 4:09 am
      case diffDays <= 6:
        return date.toFormat(this.getFromNowFormat('prevWeek'));
      // Same year: Jun 10 at 4:09 am
      case now.hasSame(date, 'year'):
        return date.toFormat(this.getFromNowFormat('month'));
      default:
        return date.toFormat(this.getFromNowFormat('year'));
    }
  }

  private formatFutureDate(date: DateTime, now: DateTime): string {
    const diffMinutes = date.diffNow('minutes').minutes;
    const diffSeconds = date.diffNow('seconds').seconds;
    const diffDays = date.diff(DateTime.now().endOf('day'), 'days').days;

    switch (true) {
      // 0 to 44 seconds: in a few seconds
      case diffSeconds <= 44:
        return 'in a few seconds';
      // 45 to 89 seconds: in a minute
      case diffSeconds <= 89:
        return 'in a minute';
      // 90 seconds to 44 minutes: in 2 minutes ... in 44 minutes
      case diffMinutes <= 44:
        const minutes = Math.floor(diffMinutes);

        return `in ${minutes === 1 ? 2 : minutes} minutes`;
      // 45 minutes to 24 hours or the same day: at 4:09 am
      case now.hasSame(date, 'day'):
        return date.toFormat(this.getFromNowFormat('sameDay'));
      // 25 hour to 48 hours or the next day: Tomorrow at 4:09 am
      case DateTime.now().endOf('day').plus({day: 1}).hasSame(date, 'day'):
        return date.toFormat(this.getFromNowFormat('nextDay'));
      // 49 hours to 6 days or the next week: Friday at 4:09 am
      case diffDays <= 6:
        return date.toFormat(this.getFromNowFormat('nextWeek'));
      // Same year: Jun 10 at 4:09 am
      case now.hasSame(date, 'year'):
        return date.toFormat(this.getFromNowFormat('month'));
      default:
        return date.toFormat(this.getFromNowFormat('year'));
    }
  }

  private getFromNowFormat(
    token: 'sameDay' | 'prevDay' | 'prevWeek' | 'nextDay' | 'nextWeek' | 'month' | 'year',
  ): string {
    switch (token) {
      case 'sameDay':
        return `'at' ${this.dateFormat.time}`;
      case 'prevDay':
        return `'yesterday at' ${this.dateFormat.time}`;
      case 'prevWeek':
        return `EEEE 'at' ${this.dateFormat.time}`;
      case 'nextDay':
        return `'tomorrow at' ${this.dateFormat.time}`;
      case 'nextWeek':
        return `EEEE 'at' ${this.dateFormat.time}`;
      case 'month':
        return `${this.dateFormat.dayOfMonth} ${this.dateFormat.time}`;
      case 'year':
        return this.dateFormat.dateTime;
    }
  }
}
