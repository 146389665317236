<span
  class="header-link"
  [class.header-link_dropdown]="dropdown"
  [class.header-link_active]="opened"
  tabindex="0"
  (click)="toggle()"
>
  <ng-content />
  <w-svg-icon *ngIf="dropdown" class="header-link__chevron" iconId="chevron-right" [size]="9" />
</span>
<div *ngIf="opened" @fadeInOut [@.disabled]="isCompactView$ | async" class="header-item__dropdown">
  <ng-container *ngTemplateOutlet="dropdown || null" />
</div>
