import _ from 'lodash';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {pluralize} from '@shared/pipes/plural.pipe';
import {HttpResource} from '@shared/services/http-resource';
import {LocalStore} from '@shared/services/local-store/local-store';
import {DataTable} from '@shared/modules/data-table/data-table.types';

import {AuthUser} from '../../services/auth-user';
import {RecipeService} from '../../services/recipe.service';
import {Connections} from '../../pages/connections/connections.service';
import {TreeNode} from '../../services/tree-node';
import {TreePicklist} from '../../types';
import {ConnectionHelper} from '../../pages/connections/connection-helper.service';
import {
  Asset,
  ConnectionAsset,
  DataPipelineAsset,
  LcapPageAsset,
  RecipeAsset,
} from '../../pages/assets-page/assets.types';
import {OemService} from '../oem/oem.service';
import {AuthUserPrivilege} from '../../services/auth-user.types';
import {LcapPagesService} from '../../services/lcap/lcap-pages.service';
import {AssetsService} from '../../pages/assets-page/assets.service';
import {DataPipelineService} from '../../pages/data-pipeline/data-pipeline.service';

import {
  AbstractFolder,
  AssetsSmartFolderType,
  ConnectionsSmartFolderType,
  DeleteFolderResult,
  Folder,
  FolderInfo,
  FolderItemsType,
  FolderNode,
  FolderUpdateParams,
  FoldersInfo,
  FoldersLoadOptions,
  FoldersPath,
  FoldersServiceState,
  MixedAssetsCounters,
  PackagesInfo,
  ProjectsFolder,
  RecipesSmartFolderType,
  SmartFolder,
  SmartFolderId,
  SmartFolderItemsInfo,
  SmartFoldersMap,
  TrashFolder,
} from './folders.types';

export type ActiveFolderIdChangedEventType = 'filters-updated' | 'user-navigation' | 'url-restored';

const SMART_FOLDER_PREFIX = 'smart';

const TYPE_TO_COUNTER_PROP: Record<
  Exclude<FolderItemsType, 'table'>,
  keyof Pick<FolderInfo, 'flow_count' | 'shared_account_count' | 'lcap_page_count' | 'data_pipeline_count'>
> = {
  recipe: 'flow_count',
  connection: 'shared_account_count',
  lcap_page: 'lcap_page_count',
  data_pipeline: 'data_pipeline_count',
};

const RECIPES_FOLDERS_INFO: SmartFolderItemsInfo[] = [
  {
    id: 'smart-recipe-active',
    name: 'Active',
  },
  {
    id: 'smart-recipe-inactive',
    name: 'Inactive',
  },
];

const CONNECTIONS_FOLDERS_INFO: SmartFolderItemsInfo[] = [
  {
    id: 'smart-connection-connected',
    name: 'Connected',
  },
  {
    id: 'smart-connection-disconnected',
    name: 'Disconnected',
  },
];

const ASSETS_FOLDERS_INFO: SmartFolderItemsInfo[] = [
  {
    id: 'smart-recipe',
    name: 'Recipes',
    children: RECIPES_FOLDERS_INFO,
  },
  {
    id: 'smart-connection',
    name: 'Connections',
    children: CONNECTIONS_FOLDERS_INFO,
  },
  {
    id: 'smart-lcap_page',
    name: 'Pages',
    requiredPrivileges: 'lcap.read',
  },
  {
    id: 'smart-data_pipeline',
    name: 'Data pipelines',
    requiredPrivileges: 'data_pipeline.read',
  },
];

export const PROJECTS_FOLDER_ID: ProjectsFolder['id'] = 'projects';
export const SMART_ALL_FOLDER_ID: SmartFolderId = 'smart-all';
export const TRASH_FOLDER_ID: TrashFolder['id'] = 'trash';

@Injectable({
  providedIn: 'root',
})
export class Folders {
  smartAllFolder: FolderNode<SmartFolder> | null = null;
  projectsFolder: FolderNode<ProjectsFolder> | null = null;
  trashFolder: FolderNode<TrashFolder> | null = null;

  activeId: AbstractFolder['id'] | null = null;
  expandedIds = new Set<AbstractFolder['id']>([PROJECTS_FOLDER_ID, SMART_ALL_FOLDER_ID]);
  smartFolders: SmartFoldersMap;

  loaded$: Observable<boolean>;
  loading$: Observable<boolean>;
  activeIdChanged$: Observable<ActiveFolderIdChangedEventType>;
  foldersCounterChange$: Observable<AbstractFolder['id']>;

  readonly defaultAssetName: string;

  private _loaded = new BehaviorSubject<boolean>(false);
  private _loading = new BehaviorSubject<boolean>(false);

  private forAssets: FolderItemsType[] = [];

  private folders: HttpResource;
  private foldersTree: HttpResource;
  private folderPackages: HttpResource;

  private _activeIdChanged = new Subject<ActiveFolderIdChangedEventType>();
  private _foldersCounterChange = new Subject<AbstractFolder['id']>();
  /**
   * It removes all symbols except:
   * \p{L} - all letters from any language
   * \p{N} - numbers
   * \p{P} - punctuation
   * \p{Z} - whitespace separators
   * ^$\n - add any symbols you want to keep
   */
  private readonly emojiRegexp = /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu;

  constructor(
    private http: HttpClient,
    private authUser: AuthUser,
    private recipeService: RecipeService,
    private connections: Connections,
    private connectionHelper: ConnectionHelper,
    private localStore: LocalStore,
    private oem: OemService,
    private lcapPagesService: LcapPagesService,
    private dataPipelineService: DataPipelineService,
    private assetsService: AssetsService,
  ) {
    this.loaded$ = this._loaded.asObservable();
    this.loading$ = this._loading.asObservable();
    this.defaultAssetName = this.oem.mixedAssets?.alias ? pluralize(0, this.oem.mixedAssets.alias, true) : 'assets';
    this.folders = new HttpResource(this.http, {url: '/folders/{{id}}'});
    this.foldersTree = new HttpResource(this.http, {url: '/utils/folders_tree'});
    this.folderPackages = new HttpResource(this.http, {url: '/web_api/folders/{{folder_id}}/packages_info'});

    this.activeIdChanged$ = this._activeIdChanged.asObservable();
    this.foldersCounterChange$ = this._foldersCounterChange.asObservable();
  }

  get loaded(): boolean {
    return this._loaded.value;
  }

  set loaded(loaded: boolean) {
    this._loaded.next(loaded);
  }

  get loading(): boolean {
    return this._loading.value;
  }

  get activeFolder(): FolderNode | null {
    return this.activeId ? this.findById(this.activeId) : null;
  }

  get homeFolder(): FolderNode<Folder> | null {
    return this.projectFolders.find(project => this.isHome(project)) || null;
  }

  get projectFolders(): Array<FolderNode<Folder>> {
    return this.projectsFolder?.getResolvedChildren() || [];
  }

  async load(opts?: FoldersLoadOptions): Promise<Folders> {
    this._loading.next(true);

    const {folders, mixed_asset_counters} = await this.folders.getAll<FoldersInfo>({
      query: {projects_mode: true},
    });

    // Setting type of folders (for `recipes`, `connections` etc.)
    this.forAssets = _.castArray(opts?.assetTypes);
    // Making custom folders tree
    this.projectsFolder = this.makeFolderNode<ProjectsFolder>({
      id: PROJECTS_FOLDER_ID,
      name: 'Projects',
      itemsCount: 0,
      children: _.sortBy(
        folders.map(folder => this.mapFolderInfo(folder, this.forAssets)),
        this.sorter,
      ),
    });

    this.trashFolder = this.makeFolderNode<TrashFolder>({
      id: TRASH_FOLDER_ID,
      name: 'Trash',
      itemsCount: 0,
    });

    this.smartAllFolder = this.makeFolderNode({
      id: SMART_ALL_FOLDER_ID,
      name: this.oem.mixedAssets ? _.capitalize(this.defaultAssetName) : 'Assets',
      smart: true,
      itemsCount: 0,
      children: ASSETS_FOLDERS_INFO.filter(
        info => !info.requiredPrivileges || this.authUser.hasPrivilege(info.requiredPrivileges),
      ).map(info => this.mapSmartFolderInfo(info)),
    });

    this.smartAllFolder.insertChild(this.trashFolder, this.smartAllFolder.getResolvedChildren().length);
    this.smartFolders = _.keyBy(this.getAllSmartFolders(), node => node.model.id) as SmartFoldersMap;

    this.initMixedAssetsCounters(mixed_asset_counters);

    // Loading folders tree state from browser storage
    this.loadState();

    this._loading.next(false);
    this.loaded = true;

    return this;
  }

  async tree(wrapProjects = false): Promise<TreePicklist> {
    const projects = await this.foldersTree.getAll({query: {projects_mode: true}});

    return wrapProjects ? [['All projects', PROJECTS_FOLDER_ID, null, projects]] : projects;
  }

  getFolderPackagesInfo(folderId: AbstractFolder['id']): Promise<{packages_info: PackagesInfo}> {
    return this.folderPackages.get<{packages_info: PackagesInfo}>({folder_id: folderId});
  }

  isActive(folder: FolderNode): boolean {
    return this.activeId === folder.model.id;
  }

  isRegular(folder: FolderNode | null): folder is FolderNode<Folder> {
    return Boolean(folder && !this.isSmart(folder) && !this.isTrash(folder) && !this.isProjects(folder));
  }

  isSmart(folder: FolderNode | null): folder is FolderNode<SmartFolder> {
    return Boolean((folder as FolderNode<SmartFolder>)?.model.smart);
  }

  isTrash(folder: FolderNode | null): folder is FolderNode<TrashFolder> {
    return folder === this.trashFolder;
  }

  isProjects(folder: FolderNode | null): folder is FolderNode<ProjectsFolder> {
    return folder === this.projectsFolder;
  }

  isProject(folder: FolderNode | null): folder is FolderNode<Folder> {
    return Boolean(folder && this.isProjects(folder.parent));
  }

  isLcapAppProject(folder: FolderNode | null): boolean {
    return this.isProject(folder) && folder.model.isLcapApp;
  }

  isHome(folder: FolderNode | null): folder is FolderNode<Folder> {
    return Boolean((folder as FolderNode<Folder>)?.model.home);
  }

  isExpanded(folder: FolderNode | null): boolean {
    return Boolean(folder && this.expandedIds.has(folder.model.id));
  }

  isSmartFolderId(id: AbstractFolder['id']): id is SmartFolderId {
    return typeof id === 'string' && id.startsWith(SMART_FOLDER_PREFIX);
  }

  findById(id: AbstractFolder['id']): FolderNode | null {
    if (!id) {
      return null;
    }

    if (id === PROJECTS_FOLDER_ID) {
      return this.projectsFolder;
    }

    if (id === TRASH_FOLDER_ID) {
      return this.trashFolder;
    }

    if (this.isSmartFolderId(id)) {
      return this.smartFolders[id];
    }

    return this.projectsFolder?.findNode<FolderNode<Folder>>(node => node.model.id === id) ?? null;
  }

  typeById(id?: AbstractFolder['id']): string | null {
    const folder = id ? this.findById(id) : null;

    if (!folder) {
      return null;
    }

    if (this.isHome(folder)) {
      return 'home assets';
    }

    if (this.isTrash(folder)) {
      return 'trash';
    }

    if (this.isSmart(folder)) {
      return 'smart';
    }

    if (this.isProjects(folder)) {
      return 'projects';
    }

    if (this.isProject(folder)) {
      return 'project';
    }

    return 'folder';
  }

  findCustomFolderById(id: Folder['id']): FolderNode<Folder> | null {
    return this.findById(id) as FolderNode<Folder> | null;
  }

  findParentProject(folder: FolderNode<Folder>): FolderNode<Folder> {
    let currentFolder = folder;

    while (currentFolder && !this.isProject(currentFolder)) {
      currentFolder = (currentFolder as FolderNode<Folder>).parent!;
    }

    return currentFolder;
  }

  getAllFolders(): FolderNode[] {
    const folders: FolderNode[] = this.getAllSmartFolders(true);

    this.projectsFolder?.walk(folder => {
      folders.push(folder);
    });

    return folders;
  }

  getAllSmartFolders(includeTrash = false): Array<FolderNode<SmartFolder>> {
    const folders: Array<FolderNode<SmartFolder>> = [];

    this.smartAllFolder?.walk(folder => {
      if (folder !== this.trashFolder || includeTrash) {
        folders.push(folder);
      }
    });

    return folders;
  }

  makeFolderNode<TFolder extends AbstractFolder>(
    model: TFolder,
    parentNode?: FolderNode<Folder | ProjectsFolder> | null,
  ): FolderNode<TFolder> {
    return new TreeNode(model, undefined, parentNode);
  }

  async createFolder(name: string, parentFolderNode: FolderNode<Folder>): Promise<FolderNode<Folder>> {
    const folderInfo = await this.folders.create<FolderInfo>({name, parent_id: parentFolderNode.model.id});

    return this.makeFolderNode(this.mapFolderInfo(folderInfo, this.forAssets), parentFolderNode);
  }

  async updateFolder(folderId: Folder['id'], params: Omit<FolderUpdateParams, 'description'>): Promise<void> {
    return this.folders.update({id: folderId}, params);
  }

  async moveFolder(folderId: Folder['id'], parentFolderId: Folder['id']): Promise<void> {
    return this.folders.update({id: folderId}, {parent_id: parentFolderId});
  }

  async deleteFolder(folderId: Folder['id']): Promise<DeleteFolderResult> {
    return this.folders.delete({id: folderId}, {query: {with_content: true}});
  }

  async moveRecipe(
    recipe: RecipeAsset,
    targetFolder: FolderNode<Folder | TrashFolder>,
  ): Promise<FolderNode<Folder | TrashFolder>> {
    if (!this.canMoveRecipe(recipe, targetFolder)) {
      throw new Error('Invalid parameters');
    }

    const recipeFolder = this.recipeService.isDeleted(recipe) ? this.trashFolder : this.findById(recipe.folder_id);

    if (this.isTrash(targetFolder)) {
      /*
       * Deleting a recipe
       * Optimistically changing items count
       */
      this.handleRecipeDeleted(recipe);

      try {
        await this.recipeService.delete(recipe.id);

        return targetFolder;
      } catch (err) {
        // Reverting counters back
        this.handleRecipeRestored(recipe, recipe.folder_id);
        throw err;
      }
    } else if (this.isTrash(recipeFolder)) {
      /*
       * Restoring a recipe into a custom folder
       * Optimistically changing items count
       */
      const target = targetFolder as FolderNode<Folder>;

      this.handleRecipeRestored(recipe, target.model.id);

      try {
        await this.recipeService.restore([recipe.id], target.model.id);

        return targetFolder;
      } catch (err) {
        // Reverting counters back
        this.handleRecipeDeleted(recipe);
        throw err;
      }
    } else {
      const isActive = this.recipeService.isActive(recipe);
      const source = recipeFolder as FolderNode<Folder>;
      const target = targetFolder as FolderNode<Folder>;

      // Optimistically changing items count
      this.changeCustomFolderCounter(-1, source, 'recipesCount');
      this.changeCustomFolderCounter(1, target, 'recipesCount');

      if (isActive) {
        this.changeCustomFolderCounter(-1, source, 'activeRecipesCount');
        this.changeCustomFolderCounter(1, target, 'activeRecipesCount');
      }

      try {
        await this.recipeService.moveToFolder(recipe.id, target.model.id);

        return targetFolder;
      } catch (err) {
        // Reverting counters back
        this.changeCustomFolderCounter(1, source, 'recipesCount');
        this.changeCustomFolderCounter(-1, target, 'recipesCount');

        if (isActive) {
          this.changeCustomFolderCounter(1, source, 'activeRecipesCount');
          this.changeCustomFolderCounter(-1, target, 'activeRecipesCount');
        }

        throw err;
      }
    }
  }

  async moveConnection(
    connection: ConnectionAsset,
    targetFolder: FolderNode<Folder | TrashFolder>,
  ): Promise<FolderNode | null> {
    if (!this.canMoveConnection(connection, targetFolder)) {
      throw new Error('Invalid parameters');
    }

    const connectionFolder = this.findCustomFolderById(connection.folder_id);

    if (this.isRegular(targetFolder)) {
      // Optimistically changing items count
      this.changeCustomFolderCounter(-1, connectionFolder, 'connectionsCount');
      this.changeCustomFolderCounter(1, targetFolder, 'connectionsCount');

      try {
        await this.connections.moveToFolder(connection.id, targetFolder.model.id);

        return targetFolder;
      } catch (err) {
        // Reverting counters back
        this.changeCustomFolderCounter(1, connectionFolder, 'connectionsCount');
        this.changeCustomFolderCounter(-1, targetFolder, 'connectionsCount');
        throw err;
      }
    } else {
      const confirmed = await this.connectionHelper.showDeleteConfirmation(connection);

      if (!confirmed) {
        return null;
      }

      // Optimistically changing items count
      this.handleConnectionPermanentlyDeleted(connection);

      try {
        await this.connections.destroy({id: connection.id});

        return targetFolder;
      } catch (err) {
        // Reverting counters back
        this.handleConnectionRestored(connection);
        throw err;
      }
    }
  }

  async moveLcapPage(
    appPage: LcapPageAsset,
    targetFolder: FolderNode<Folder | TrashFolder>,
  ): Promise<FolderNode | null> {
    if (!this.canMoveLcapPage(appPage, targetFolder)) {
      throw new Error('Invalid parameters');
    }

    const appFolder = this.findCustomFolderById(appPage.folder_id);

    if (this.isRegular(targetFolder)) {
      // Optimistically changing items count
      this.changeCustomFolderCounter(-1, appFolder, 'lcapPagesCount');
      this.changeCustomFolderCounter(1, targetFolder, 'lcapPagesCount');

      try {
        await this.lcapPagesService.moveToFolder(appPage.id, targetFolder.model.id);

        return targetFolder;
      } catch (err) {
        // Reverting counters back
        this.changeCustomFolderCounter(1, appFolder, 'lcapPagesCount');
        this.changeCustomFolderCounter(-1, targetFolder, 'lcapPagesCount');
        throw err;
      }
    } else {
      const confirmed = await this.lcapPagesService.showPageDeleteConfirmation(appPage);

      if (!confirmed) {
        return null;
      }

      // Optimistically changing items count
      this.handleLcapPagePermanentlyDeleted(appPage);

      try {
        await this.lcapPagesService.deletePage(appPage.id);

        return targetFolder;
      } catch (err) {
        // Reverting counters back
        this.handleLcapPageRestored(appPage);
        throw err;
      }
    }
  }

  async moveDataPipeline(
    dataPipeline: DataPipelineAsset,
    targetFolder: FolderNode<Folder | TrashFolder>,
  ): Promise<FolderNode | null> {
    if (!this.canMoveDataPipeline(dataPipeline, targetFolder)) {
      throw new Error('Invalid parameters');
    }

    const currentFolder = this.findCustomFolderById(dataPipeline.folder_id);

    if (this.isRegular(targetFolder)) {
      // Optimistically changing items count
      this.changeCustomFolderCounter(-1, currentFolder, 'dataPipelinesCount');
      this.changeCustomFolderCounter(1, targetFolder, 'dataPipelinesCount');

      try {
        await this.dataPipelineService.moveToFolder(dataPipeline.id, targetFolder.model.id);

        return targetFolder;
      } catch (err) {
        // Reverting counters back
        this.changeCustomFolderCounter(1, currentFolder, 'dataPipelinesCount');
        this.changeCustomFolderCounter(-1, targetFolder, 'dataPipelinesCount');
        throw err;
      }
    }

    if (this.dataPipelineService.isActive(dataPipeline)) {
      return null;
    }

    const confirmed = await this.dataPipelineService.showDeleteConfirmation(dataPipeline);

    if (!confirmed) {
      return null;
    }

    // Optimistically changing items count
    this.handleDataPipelinePermanentlyDeleted(dataPipeline);

    try {
      await this.dataPipelineService.delete(dataPipeline.id);

      return targetFolder;
    } catch (err) {
      // Reverting counters back
      this.handleDataPipelineRestored(dataPipeline);
      throw err;
    }
  }

  moveAsset(asset: Asset, targetFolder: FolderNode<Folder | TrashFolder>): Promise<FolderNode | null> | null {
    if (this.assetsService.isRecipeAsset(asset)) {
      return this.moveRecipe(asset, targetFolder);
    }

    if (this.assetsService.isConnectionAsset(asset)) {
      return this.moveConnection(asset, targetFolder);
    }

    if (this.assetsService.isLcapPageAsset(asset)) {
      return this.moveLcapPage(asset, targetFolder);
    }

    if (this.assetsService.isDataPipelineAsset(asset)) {
      return this.moveDataPipeline(asset, targetFolder);
    }

    return null;
  }

  hasPrivilegeForNode(type: 'create' | 'update' | 'delete', folderNode: FolderNode): boolean {
    const isProject = type === 'create' ? this.isProjects(folderNode) : this.isProject(folderNode);

    return this.authUser.hasPrivilege(`${isProject ? 'project' : 'folder'}.${type}` as AuthUserPrivilege);
  }

  canCreateFolderOrProject(parentFolderId: AbstractFolder['id']): boolean {
    if (parentFolderId === PROJECTS_FOLDER_ID) {
      return this.authUser.hasPrivilege('project.create');
    }

    const parentFolderNode = this.findById(parentFolderId);

    return Boolean(
      parentFolderNode && this.hasPrivilegeForNode('create', parentFolderNode) && !this.isHome(parentFolderNode),
    );
  }

  canReadTable(parentFolderId: AbstractFolder['id']): boolean {
    if (parentFolderId === PROJECTS_FOLDER_ID) {
      return false;
    }

    const parentFolderNode = this.findById(parentFolderId);

    return Boolean(
      parentFolderNode &&
        this.isProject(parentFolderNode) &&
        !this.isHome(parentFolderNode) &&
        this.authUser.hasPrivilege('workato_db_table.read'),
    );
  }

  canCreateTable(parentFolderId: AbstractFolder['id']): boolean {
    return this.canReadTable(parentFolderId) && this.authUser.hasPrivilege('workato_db_table.create');
  }

  canCreateLcapPage(parentFolderId: AbstractFolder['id']): boolean {
    const parentFolderNode = this.findById(parentFolderId);

    return Boolean(
      parentFolderNode && !this.isHome(parentFolderNode) && this.authUser.hasPrivilege(['lcap.update', 'lcap.create']),
    );
  }

  canCreateDataPipeline(parentFolderId: AbstractFolder['id']): boolean {
    const parentFolderNode = this.findById(parentFolderId);

    return Boolean(
      parentFolderNode &&
        !this.isHome(parentFolderNode) &&
        this.authUser.hasPrivilege(['data_pipeline.update', 'data_pipeline.create']),
    );
  }

  canUpdateFolder(folderNode: FolderNode, newParentFolderNode: FolderNode): boolean {
    return Boolean(
      folderNode &&
        newParentFolderNode &&
        this.hasPrivilegeForNode('update', folderNode) &&
        (folderNode.parent === newParentFolderNode || this.hasPrivilegeForNode('create', newParentFolderNode)) &&
        // Can't move into trash or smart folder
        this.isRegular(newParentFolderNode) &&
        // Can't move into home folder
        !this.isHome(newParentFolderNode) &&
        // Can't move folder into itself
        folderNode !== newParentFolderNode &&
        // Can't move folder into it's descendant
        !folderNode.findNode(folder => folder === newParentFolderNode),
    );
  }

  canMoveFolder(folderNode: FolderNode, newParentFolderNode: FolderNode): boolean {
    return this.canUpdateFolder(folderNode, newParentFolderNode) && folderNode.parent !== newParentFolderNode;
  }

  canDeleteFolderOrProject(folderNode: FolderNode | null): boolean {
    return (
      this.isRegular(folderNode) &&
      this.hasPrivilegeForNode('delete', folderNode) &&
      !folderNode.model.activeRecipesCount
    );
  }

  canMoveRecipe(recipe: RecipeAsset, targetFolder: FolderNode): boolean {
    if (!this.canMoveIntoFolder(targetFolder, recipe.deleted_at ? TRASH_FOLDER_ID : recipe.folder_id)) {
      return false;
    } else if (this.isTrash(targetFolder)) {
      return this.authUser.hasPrivilege('recipe.delete') && !this.recipeService.isActive(recipe);
    } else {
      return this.authUser.hasPrivilege('recipe.update');
    }
  }

  canMoveConnection(connection: ConnectionAsset, targetFolder: FolderNode): boolean {
    if (!this.canMoveIntoFolder(targetFolder, connection.folder_id)) {
      return false;
    } else if (this.isTrash(targetFolder)) {
      return this.authUser.hasPrivilege('connection.delete') && this.connectionHelper.canDeleteConnection(connection);
    } else {
      return this.authUser.hasPrivilege('connection.update');
    }
  }

  canMoveLcapPage(appPage: LcapPageAsset, targetFolder: FolderNode): boolean {
    if (!this.canMoveIntoFolder(targetFolder, appPage.folder_id) || this.isHome(targetFolder)) {
      return false;
    } else if (this.isTrash(targetFolder)) {
      return this.authUser.hasPrivilege('lcap.delete');
    } else if (this.isRegular(targetFolder) && this.authUser.hasPrivilege('lcap.update')) {
      const projectFolder = this.findParentProject(targetFolder);

      return this.isLcapAppProject(projectFolder);
    } else {
      return false;
    }
  }

  canMoveDataPipeline(dataPipeline: DataPipelineAsset, targetFolder: FolderNode): boolean {
    if (!this.canMoveIntoFolder(targetFolder, dataPipeline.folder_id)) {
      return false;
    } else if (this.isTrash(targetFolder)) {
      return this.authUser.hasPrivilege('data_pipeline.delete') && !this.dataPipelineService.isActive(dataPipeline);
    } else {
      return this.authUser.hasPrivilege('data_pipeline.update') && !this.isHome(targetFolder);
    }
  }

  handleRecipeChanged(
    newRecipeState: Pick<RecipeAsset, 'running' | 'last_run_at'>,
    oldRecipeState: Pick<RecipeAsset, 'running' | 'last_run_at'>,
    folderId: Folder['id'],
  ) {
    if (newRecipeState.running && !oldRecipeState.running) {
      // Recipe was started
      this.changeBuiltinFolderCounter(1, this.smartFolders['smart-recipe-active']);
      this.changeCustomFolderCounter(1, this.findCustomFolderById(folderId), 'activeRecipesCount');

      this.changeBuiltinFolderCounter(-1, this.smartFolders['smart-recipe-inactive']);
    } else if (!newRecipeState.running && oldRecipeState.running) {
      // Recipe was stopped
      this.changeBuiltinFolderCounter(-1, this.smartFolders['smart-recipe-active']);
      this.changeBuiltinFolderCounter(1, this.smartFolders['smart-recipe-inactive']);
      this.changeCustomFolderCounter(-1, this.findCustomFolderById(folderId), 'activeRecipesCount');
    }
  }

  handleRecipeDeleted(recipe: Pick<RecipeAsset, 'folder_id' | 'running' | 'last_run_at'>) {
    this.changeCustomFolderCounter(-1, this.findCustomFolderById(recipe.folder_id), 'recipesCount');
    this.changeBuiltinFolderCounter(1, this.trashFolder);

    this.changeBuiltinFolderCounter(-1, this.smartFolders['smart-recipe-inactive'], true);
  }

  handleRecipesPermanentlyDeleted(count: number | 'all') {
    const deletedCount: number = count === 'all' ? this.trashFolder!.model.itemsCount : count;

    this.changeBuiltinFolderCounter(-deletedCount, this.trashFolder);
  }

  handleConnectionPermanentlyDeleted(connection: ConnectionAsset) {
    this.changeCustomFolderCounter(-1, this.findCustomFolderById(connection.folder_id), 'connectionsCount');

    const smartFolderId: SmartFolderId = this.connectionHelper.isConnected(connection)
      ? 'smart-connection-connected'
      : 'smart-connection-disconnected';

    this.changeBuiltinFolderCounter(-1, this.smartFolders[smartFolderId], true);
  }

  handleLcapPagePermanentlyDeleted(appPage: LcapPageAsset) {
    this.changeCustomFolderCounter(-1, this.findCustomFolderById(appPage.folder_id), 'lcapPagesCount');
    this.changeBuiltinFolderCounter(-1, this.smartFolders['smart-lcap_page'], true);
  }

  handleDataTablePermanentlyDeleted(folderId: DataTable['folder_id']) {
    this.changeCustomFolderCounter(-1, this.findCustomFolderById(folderId), 'dataTablesCount');
  }

  handleDataPipelinePermanentlyDeleted(dataPipeline: DataPipelineAsset) {
    this.changeCustomFolderCounter(-1, this.findCustomFolderById(dataPipeline.folder_id), 'dataPipelinesCount');
    this.changeBuiltinFolderCounter(-1, this.smartFolders['smart-data_pipeline'], true);
  }

  handleRecipeRestored(recipe: RecipeAsset, targetFolderId: Folder['id']) {
    const restoredToFolder = this.findCustomFolderById(targetFolderId);

    this.changeCustomFolderCounter(1, restoredToFolder, 'recipesCount');
    this.changeBuiltinFolderCounter(-1, this.trashFolder);

    this.changeBuiltinFolderCounter(1, this.smartFolders['smart-recipe-inactive'], true);
  }

  handleConnectionRestored(connection: ConnectionAsset) {
    const restoredToFolder = this.findCustomFolderById(connection.folder_id);

    this.changeCustomFolderCounter(1, restoredToFolder, 'connectionsCount');

    const smartFolderId: SmartFolderId = this.connectionHelper.isConnected(connection)
      ? 'smart-connection-connected'
      : 'smart-connection';

    this.changeBuiltinFolderCounter(1, this.smartFolders[smartFolderId], true);
  }

  handleLcapPageRestored(appPage: LcapPageAsset) {
    const restoredToFolder = this.findCustomFolderById(appPage.folder_id);

    this.changeCustomFolderCounter(1, restoredToFolder, 'lcapPagesCount');
    this.changeBuiltinFolderCounter(1, this.smartFolders['smart-lcap_page'], true);
  }

  handleDataPipelineRestored(dataPipeline: DataPipelineAsset) {
    const restoredToFolder = this.findCustomFolderById(dataPipeline.folder_id);

    this.changeCustomFolderCounter(1, restoredToFolder, 'dataPipelinesCount');
    this.changeBuiltinFolderCounter(1, this.smartFolders['smart-data_pipeline'], true);
  }

  handleFolderDeleted(folderNode: FolderNode<Folder>, connectedConnectionsCount: number) {
    const recipesCount = folderNode.model.recipesCount;
    const connectionsCount = folderNode.model.connectionsCount;
    const disconnectedConnectionsCount = connectionsCount - connectedConnectionsCount;
    const lcapPagesCount = folderNode.model.lcapPagesCount;
    const dataPipelinesCount = folderNode.model.dataPipelinesCount;

    this.changeBuiltinFolderCounter(recipesCount, this.trashFolder);
    this.changeBuiltinFolderCounter(-recipesCount, this.smartFolders['smart-recipe-inactive'], true);
    this.changeBuiltinFolderCounter(-connectionsCount, this.smartFolders['smart-connection'], true);
    this.changeBuiltinFolderCounter(-connectedConnectionsCount, this.smartFolders['smart-connection-connected']);
    this.changeBuiltinFolderCounter(-disconnectedConnectionsCount, this.smartFolders['smart-connection-disconnected']);
    this.changeBuiltinFolderCounter(-lcapPagesCount, this.smartFolders['smart-lcap_page'], true);
    this.changeBuiltinFolderCounter(-dataPipelinesCount, this.smartFolders['smart-data_pipeline'], true);
  }

  parseIdFromStr(str: string): AbstractFolder['id'] | null {
    if (str === PROJECTS_FOLDER_ID || str === TRASH_FOLDER_ID || this.isSmartFolderId(str)) {
      return str;
    }

    return Number(str) || null;
  }

  activate(folder: FolderNode | null, eventType: ActiveFolderIdChangedEventType = 'user-navigation') {
    this.activeId = folder ? folder.model.id : null;

    if (!folder) {
      return;
    }

    this._activeIdChanged.next(eventType);

    // Expanding all parent folders
    _(folder.branch)
      .slice(0, -1)
      .map('model.id')
      .forEach((id: AbstractFolder['id']) => this.expandedIds.add(id));
  }

  toggleExpanded(folder: FolderNode, flag?: boolean) {
    if (!folder.hasChildren()) {
      return;
    }

    const {id} = folder.model;
    const expanded = this.expandedIds.has(id);

    if (typeof flag !== 'boolean') {
      flag = !expanded;
    } else if (flag === expanded) {
      return;
    }

    if (flag) {
      this.expandedIds.add(id);
    } else {
      this.expandedIds.delete(id);
    }

    this.saveState();
  }

  buildSmartFolderId(
    type: AssetsSmartFolderType | 'all',
    subType?: RecipesSmartFolderType | ConnectionsSmartFolderType,
  ): SmartFolderId {
    let smartFolderId = `${SMART_FOLDER_PREFIX}-${type}`;

    if (subType) {
      smartFolderId += `-${subType}`;
    }

    return smartFolderId as SmartFolderId;
  }

  handleChildFolderChange(folderNode: FolderNode, childFolderNode: FolderNode<Folder>, event: 'added' | 'removed') {
    if (!this.isRegular(folderNode)) {
      return;
    }

    const sign = event === 'added' ? 1 : -1;

    const recipesCount = childFolderNode.model.recipesCount;
    const connectionsCount = childFolderNode.model.connectionsCount;
    const lcapPagesCount = childFolderNode.model.lcapPagesCount;
    const dataPipelinesCount = folderNode.model.dataPipelinesCount;

    this.changeCustomFolderCounter(sign * recipesCount, folderNode, 'recipesCount');
    this.changeCustomFolderCounter(sign * connectionsCount, folderNode, 'connectionsCount');
    this.changeCustomFolderCounter(sign * lcapPagesCount, folderNode, 'lcapPagesCount');
    this.changeCustomFolderCounter(sign * dataPipelinesCount, folderNode, 'dataPipelinesCount');
  }

  generateFoldersPath(folder: FolderNode): FoldersPath {
    const path: FoldersPath = [];

    while (folder && this.isRegular(folder)) {
      path.unshift({name: folder.model.name, id: folder.model.id});
      folder = folder.parent!;
    }

    return path;
  }

  sorter = (folder: Folder): string => {
    const name = folder.home ? '' : folder.name.toLowerCase();

    return name.replace(this.emojiRegexp, '').trim();
  };

  private canMoveIntoFolder(targetFolder: FolderNode, sourceFolderId: AbstractFolder['id']): boolean {
    return !this.isSmart(targetFolder) && targetFolder !== this.findById(sourceFolderId);
  }

  private mapFolderInfo(info: FolderInfo, types: FolderItemsType[]): Folder {
    const children = (info.children || []).map(child => this.mapFolderInfo(child, types));
    const counters = _.pick(
      info,
      types.map(type => TYPE_TO_COUNTER_PROP[type]),
      'workato_db_table_count',
    );
    const itemsCount = Object.values(counters).reduce((sum, counter) => sum + counter, 0);

    return {
      id: info.id,
      name: info.home ? `Home ${this.defaultAssetName}` : info.name,
      home: info.home,
      isLcapApp: info.lcap_app_enabled, // Only project can be an app
      itemsCount: itemsCount + children.reduce((sum, child) => sum + child.itemsCount, 0),
      recipesCount: info.flow_count + children.reduce((sum, child) => sum + child.recipesCount, 0),
      activeRecipesCount: info.active_flow_count + children.reduce((sum, child) => sum + child.activeRecipesCount, 0),
      connectionsCount: info.shared_account_count + children.reduce((sum, child) => sum + child.connectionsCount, 0),
      lcapPagesCount: info.lcap_page_count + children.reduce((sum, child) => sum + child.lcapPagesCount, 0),
      dataTablesCount: info.workato_db_table_count, // Workato DB is available only on project level
      dataPipelinesCount: info.data_pipeline_count + children.reduce((sum, child) => sum + child.dataPipelinesCount, 0),
      children: _.sortBy(children, this.sorter),
    };
  }

  private mapSmartFolderInfo(info: SmartFolderItemsInfo): SmartFolder {
    const children = (info.children || []).map(child => this.mapSmartFolderInfo(child));

    return {
      name: info.name,
      id: info.id,
      smart: true,
      itemsCount: 0,
      children,
    };
  }

  private loadState() {
    const folderIds = this.getAllFolders().map(folder => folder.model.id);
    const state: Partial<FoldersServiceState> = this.localStore.getItem('foldersState') || {};

    _.forEach(state.expandedIds, id => {
      if (folderIds.includes(id)) {
        this.expandedIds.add(id);
      }
    });
  }

  private saveState() {
    const state: FoldersServiceState = {
      expandedIds: [...this.expandedIds],
    };

    this.localStore.setItem('foldersState', state);
  }

  private initMixedAssetsCounters(assetCounters: MixedAssetsCounters) {
    this.trashFolder!.model.itemsCount = assetCounters.recipe.deleted || 0;

    _.forEach(assetCounters, (counter, id: keyof MixedAssetsCounters) => {
      const folder = this.smartFolders[this.buildSmartFolderId(id)];

      if (!this.forAssets.includes(id)) {
        return;
      }

      _.forEach(counter, (value: number, counterType) => {
        if (counterType === 'all') {
          folder.model.itemsCount = value;
        } else {
          const childFolder = folder.findChild(child => child.model.id === `${folder.model.id}-${counterType}`);

          if (childFolder) {
            childFolder.model.itemsCount = value;
          }
        }
      });

      this.smartFolders[SMART_ALL_FOLDER_ID].model.itemsCount += counter.all;
    });
  }

  private changeCustomFolderCounter(
    delta: number,
    folderNode: FolderNode<Folder> | null | undefined,
    counter: keyof Pick<
      Folder,
      | 'recipesCount'
      | 'activeRecipesCount'
      | 'connectionsCount'
      | 'lcapPagesCount'
      | 'dataTablesCount'
      | 'dataPipelinesCount'
    >,
  ) {
    if (!folderNode) {
      return;
    }

    folderNode.model[counter] = Math.max(0, folderNode.model[counter] + delta);

    if (counter !== 'activeRecipesCount') {
      folderNode.model.itemsCount = Math.max(0, folderNode.model.itemsCount + delta);
    }

    this._foldersCounterChange.next(folderNode.model.id);

    const parentNode = folderNode.parent;

    if (parentNode && !this.isProjects(parentNode)) {
      this.changeCustomFolderCounter(delta, parentNode, counter);
      this._foldersCounterChange.next(parentNode.model.id);
    }
  }

  private changeBuiltinFolderCounter(
    delta: number,
    folderNode: FolderNode<SmartFolder | TrashFolder> | null | undefined,
    recursive = false,
  ) {
    if (!folderNode) {
      return;
    }

    folderNode.model.itemsCount = Math.max(0, folderNode.model.itemsCount + delta);

    this._foldersCounterChange.next(folderNode.model.id);

    const parentNode = folderNode.parent;

    if (recursive && parentNode) {
      this.changeBuiltinFolderCounter(delta, parentNode, recursive);
      this._foldersCounterChange.next(parentNode.model.id);
    }
  }
}
