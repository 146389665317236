import {Component, Input, NgModule, OnInit, ViewEncapsulation} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Observable} from 'rxjs';

import {AlertService} from '@shared/services/alert';
import {PageSpinnerService} from '@shared/services/page-spinner.service';
import {Toast} from '@shared/components/toast/toasts.types';
import {IconButtonComponent} from '@shared/components/icon-button/icon-button.component';
import {SanitizeHtmlPipe} from '@shared/pipes/sanitize-html.pipe';

import {SharedModule} from '../../shared.module';

import {ToastComponent} from './toast/toast.component';
import {ServerAlert} from './toasts.types';

@Component({
  selector: 'w-toasts',
  templateUrl: './toasts.component.html',
  animations: [
    trigger('fadeInOut', [
      state('in', style({opacity: 1, transform: 'translateY(0)'})),
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(-50%)'}),
        animate('.2s cubic-bezier(.3,.02,.2,1)'),
      ]),
      transition(':leave', [animate('.2s cubic-bezier(.3,.02,.2,1)', style({opacity: 0, height: 0}))]),
    ]),
  ],
  styleUrls: ['./toasts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastsComponent implements OnInit {
  @Input({alias: 'alerts', required: true}) alertsFromServer: ServerAlert[];
  loaded$: Observable<boolean>;

  readonly MAX_TOASTS_COUNT = 3;

  constructor(
    private alertService: AlertService,
    private pageSpinner: PageSpinnerService,
  ) {
    this.loaded$ = this.pageSpinner.isHidden$;
  }

  ngOnInit() {
    this.alertsFromServer.forEach(alert => {
      switch (alert.type) {
        case 'notice':
          this.alertService.success(alert.title, alert.content);
          break;
        case 'error':
          this.alertService.error(alert.title, alert.content);
          break;
        case 'info':
        case 'warning':
          this.alertService.info(alert.title, alert.content);
          break;
      }
    });
  }

  get toasts(): Toast[] {
    return this.alertService.toasts;
  }

  handleClose(id: Toast['id']) {
    this.alertService.remove(id);
  }
}

const components = [ToastsComponent, ToastComponent];

@NgModule({
  imports: [SharedModule, IconButtonComponent, SanitizeHtmlPipe],
  declarations: components,
  exports: components,
})
export class ToastsModule {}
