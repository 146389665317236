<div class="w-dialog {{ class }}" (click)="handleDialogClick($event)" wTrapScroll="always">
  <div class="w-dialog__overlay"></div>
  <div
    #contentContainer
    class="w-dialog__container"
    [ngStyle]="{'width.px': width}"
    (mousedown)="handleContentMousedown()"
    (click)="handleContentClick()"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="trapFocusAutoCapture"
  >
    <div class="w-dialog__content">
      <ng-container
        *ngIf="isTemplateContent(content); else componentContainer"
        [ngTemplateOutlet]="content"
        [ngTemplateOutletContext]="contentInputs"
        [ngTemplateOutletInjector]="injector"
      />
      <ng-template #componentContainer />
    </div>
    <button
      *ngIf="isCloseButtonShown"
      #confirmationPopover="overlay"
      class="w-dialog__close"
      type="button"
      (click)="handleClose()"
      wOverlayPlacement="bottom right"
      wOverlayShowOn="manual"
      wIconButton="close"
      wIconButtonAriaLabel="Close dialog"
      [wOverlay]="closeConfirmationBubble"
    ></button>
  </div>
</div>

<ng-template #closeConfirmationBubble let-overlay>
  <w-bubble [header]="closeConfirmation!.header" [content]="closeConfirmation!.content">
    <ng-template wBubbleActions>
      <w-button size="compact" theme="negative" (onClick)="close()">
        {{ closeButtonTitle }}
      </w-button>
      <w-button type="outline" size="compact" theme="neutral" (onClick)="overlay.hide()">
        {{ cancelButtonTitle }}
      </w-button>
    </ng-template>
  </w-bubble>
</ng-template>
