import {SelectLikeControlType} from './form-fields';

export const LOADING_INDICATOR = Symbol('loading-indicator');

export type LoadingIndicator = typeof LOADING_INDICATOR;

export type PicklistOptions = Picklist | StepPicklist | TreePicklist | LoadingIndicator;

export interface PicklistRegistry {
  [fieldPathString: string]: PicklistOptions;
}

export type PicklistFor<TControlType extends SelectLikeControlType> = TControlType extends 'tree'
  ? TreePicklist | LoadingIndicator
  : TControlType extends 'select'
    ? StepPicklist | Picklist | LoadingIndicator
    : TControlType extends 'multiselect'
      ? StepPicklist | Picklist | LoadingIndicator
      : PicklistOptions;

export type AsyncPicklistFor<
  TControlType extends SelectLikeControlType,
  TSupportsLegacy extends boolean = true,
> = TControlType extends 'tree'
  ? TreePicklist
  : TControlType extends 'select'
    ? Picklist | (TSupportsLegacy extends false ? never : LegacyPicklist)
    : TControlType extends 'multiselect'
      ? Picklist
      : Picklist | TreePicklist | (TSupportsLegacy extends false ? never : LegacyPicklist);

export type Picklist = PicklistItem[];

export type LegacyPicklist = LegacyPicklistItem[];

export type StepPicklist = StepPicklistItem[];

export type TreePicklist<TID = string | number> = Array<TreePicklistItem<TID>>;

export type PicklistItem = [title: string, value: any, description?: string | null];

export type LegacyPicklistItem = string;

export type StepPicklistItem = [title: string, value: any, description?: string | null, disabled?: boolean];

export type TreePicklistItem<TID = string | number> = [
  title: string,
  id: TID,
  description: string | null,
  children: TreePicklistItem[] | false,
  iconId?: SvgIcon['id'] | null,
];

export interface TreeSelectValue<TID = string | number> {
  ids: Array<TreePicklistItem<TID>[1]>;
  titles: Array<TreePicklistItem<TID>[0]>;
}
