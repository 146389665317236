import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import successIcon from '@icons/check-round.svg';
import errorIcon from '@icons/exclamation-rhomb.svg';

import {HtmlSanitizerConfig} from '../../services/html-sanitizer.service';

import {Toast} from './toasts.types';

const SANITIZE_CONFIG: HtmlSanitizerConfig = {
  allowedTags: 'a b br i div ul li',
  allowedAttrs: 'href target',
};

@Directive()
export abstract class AbstractToastComponent implements OnInit {
  @Input({required: true}) toast: Toast;

  @Output() close = new EventEmitter<void>();

  readonly sanitizeConfig = SANITIZE_CONFIG;

  ngOnInit() {
    if (this.toast.timer) {
      this.toast.timer.resume();
    }
  }

  get toastIcon(): SvgIcon['id'] | null {
    if (this.toast.icon) {
      return this.toast.icon.id;
    }

    switch (this.toast.type) {
      case 'success':
        return successIcon.id;
      case 'error':
        return errorIcon.id;
      default:
        return null;
    }
  }

  handleActionClick() {
    this.toast.options.action!();

    if (this.toast.options.actionRemoveToast) {
      this.close.emit();
    }
  }

  handleMouseenter() {
    if (this.toast.timer) {
      this.toast.timer.pause();
    }
  }

  handleMouseleave() {
    if (this.toast.timer) {
      this.toast.timer.resume();
    }
  }
}
