import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TooltipDirective} from '../../../modules/tooltip/tooltip.directive';

@Component({
  selector: 'w-date-time-label-template',
  templateUrl: './date-time-label-template.component.html',
  styleUrls: ['./date-time-label-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, TooltipDirective],
})
export class DateTimeLabelTemplateComponent {
  @Input({required: true}) formattedDate: string;
  @Input({required: true}) timeAttributeDate: string;
  @Input() toolTipDate: string | null;
  @Input() tooltipPlacement: TooltipDirective['placement'] = 'top';
}
