import {Directive, ElementRef, Input, NgZone, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {RouterLink} from '@angular/router';

import {MixpanelEvent, MixpanelEventParams, MixpanelService} from '@shared/services/mixpanel';

@Directive({
  selector: '[wMixpanel]',
  standalone: true,
})
export class MixpanelDirective implements OnInit, OnDestroy {
  @Input('wMixpanel') mixpanelEvent?: MixpanelEvent;
  @Input('wMixpanelOptions') options?: MixpanelEventParams;

  private elem: HTMLElement;

  constructor(
    private mixpanelService: MixpanelService,
    private elemRef: ElementRef,
    private ngZone: NgZone,
    @Optional() @Self() private routerLink?: RouterLink,
  ) {}

  ngOnInit() {
    this.elem = this.elemRef.nativeElement;

    this.ngZone.runOutsideAngular(() => {
      this.elem.addEventListener('click', this.handleClick);
    });
  }

  ngOnDestroy() {
    this.elem.removeEventListener('click', this.handleClick);
  }

  handleClick = (event: MouseEvent) => {
    if (!this.mixpanelEvent) {
      return;
    }

    if (this.elem.tagName === 'A' && !this.routerLink) {
      this.mixpanelService.trackDomEvent(event, this.mixpanelEvent, this.options);
    } else {
      this.mixpanelService.track(this.mixpanelEvent, this.options);
    }
  };
}
