import {Injectable, inject} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

import {BaseRequestError, RequestError} from './http-resource';
import {Log} from './log';
import {AbstractAuthService} from './abstract-auth.service';
import {PageSpinnerService} from './page-spinner.service';
import {ErrorData, ErrorPageProps, ErrorPageType} from './service-pages.types';

export const PAGE_ERROR_DATA: Record<ErrorPageType, ErrorData> = {
  '404': {
    type: 404,
    title: 'Oops! That page doesn’t exist.',
    buttons: {
      home: true,
      back: true,
    },
  },
  '422': {
    type: 422,
    title: 'Invalid request',
    buttons: {
      home: true,
    },
  },
  '500': {
    type: 500,
    title: 'Oops! Something went wrong.',
    description: 'We encountered a server error. Sorry about that.',
    buttons: {
      refresh: true,
    },
  },
  '503': {
    type: 503,
    title: 'Service unavailable',
    buttons: {
      home: true,
      back: true,
      refresh: true,
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class ServicePagesService {
  protected router = inject(Router);
  protected authUser = inject(AbstractAuthService);
  protected pageSpinner = inject(PageSpinnerService);
  protected log = inject(Log);
  protected errorPagePathPrefix = '';

  showPageLoadError({err, ...pageProps}: {err?: Error} & ErrorPageProps = {}): Promise<boolean> {
    if (err) {
      this.log.error(err);

      if (this.isBaseRequestError(err)) {
        if (err.isUnauthorized && !this.authUser.authenticated) {
          this.authUser.returnUrl = this.router.url;

          return this.router.navigateByUrl(this.authUser.signInUrl, {replaceUrl: true});
        }

        if (err.isServerError) {
          const description = this.getDescriptionForServerError(err);

          return this.showPageError({type: '500', description, ...pageProps});
        }

        if (err.isNotFound) {
          return this.showPageError({type: '404', ...pageProps});
        }

        if (err.isServiceUnavailable) {
          return this.showPageError({
            type: '503',
            description: err.message,
            ...pageProps,
          });
        }
      }
    }

    return this.showPageError(pageProps);
  }

  showNotFoundPage(): Promise<boolean> {
    return this.showPageError({type: '404'});
  }

  showPageError({type, ...pageProps}: {type?: ErrorPageType} & ErrorPageProps = {}): Promise<boolean> {
    const pageUrl = type && PAGE_ERROR_DATA.hasOwnProperty(type) ? `/_ng_/${type}` : '/_ng_/page-load-error';

    this.pageSpinner.hide();

    return this.router.navigateByUrl(`${this.errorPagePathPrefix}${pageUrl}`, {
      skipLocationChange: true,
      state: pageProps,
    });
  }

  protected isBaseRequestError(error: Error): error is BaseRequestError {
    return error instanceof RequestError;
  }

  protected getDescriptionForServerError(err: BaseRequestError): string {
    let description = PAGE_ERROR_DATA['500'].description ?? 'Internal error';
    const requestId = this.getRequestId(err);

    if (requestId) {
      description += `<br/>Request ID: ${requestId}`;
    }

    return description;
  }

  protected getRequestId(err: Error): string | null {
    if (
      err instanceof RequestError &&
      err.details instanceof HttpErrorResponse &&
      err.details.headers.has('x-request-id')
    ) {
      /*
       * In some cases it is possible to have two `x-request-id` in the header
       * and `headers.get` returns two same values joined by comma
       */
      return err.details.headers.getAll('x-request-id')![0];
    }

    return null;
  }
}
