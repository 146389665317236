import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthUser} from '../../services/auth-user';
import {FixedHeaderService} from '../../services/fixed-header.service';

@Component({
  selector: 'w-page-top-sticky-area',
  templateUrl: './page-top-sticky-area.component.html',
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./page-top-sticky-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageTopStickyAreaComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class.page-top-sticky-area') readonly ensureHostClass = true;

  @HostBinding('class.below-top-header')
  get notAuthenticated(): boolean {
    return !this.authUser.authenticated;
  }

  constructor(
    private elementRef: ElementRef,
    private fixedHeader: FixedHeaderService,
    private authUser: AuthUser,
  ) {}

  ngAfterViewInit() {
    this.fixedHeader.add(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.fixedHeader.remove(this.elementRef.nativeElement);
  }
}
